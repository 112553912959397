import React, { Component } from "react";
import { Button, Modal, Toast } from "react-bootstrap";
import FormAdmin from "../components/FormAdminMasterAset_Com";
import SideBar from "../components/SideBar_Com";
import { getTime } from "../logic/handler";

class FormAdminMasterAset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showToast: [],
      toast_data: [],
      showModal: false,
      modalData: {},
    };
  }

  close(index, pos) {
    //destroy
    let show_arr = [...this.state.showToast];
    show_arr[index] = false;
    this.setState(
      {
        showToast: show_arr,
      },
      this.destroy(pos)
    );
  }

  destroy(position) {
    //nge rerender ketika masih ada yg di show apakah ga bakal masalah?
    let toast_data = [...this.state.toast_data];
    toast_data.splice(position, 1, null);
    this.setState({
      toast_data: toast_data,
    });
  }

  move = () => {
    this.props.history.push("/TableAdminMasterAset");
  };

  generateToast = (status, message) => {
    let idx = this.state.showToast.length;
    let header = "";
    let header_class = "";
    switch (status) {
      case 0: //success
        header_class = "success";
        header = "Success!!";
        break;
      case 1: //error from input
        header_class = "warning";
        header = "Error!!";
        break;
      case 2: //error from server
        header_class = "danger";
        header = "Error!!";
        break;
      default:
        header_class = "";
        header = "Unknown";
        break;
    }
    let data = {
      class: header_class,
      header: header,
      msg: message,
      idx: idx,
      pos: this.state.toast_data.length,
    };
    this.setState({
      toast_data: this.state.toast_data.concat(data),
      showToast: this.state.showToast.concat(true),
    });
  };

  showModal = (type, name, uniq) => {
    this.setState({
      showModal: true,
      modalData: {
        name: name,
        uniq: uniq,
        type: type,
      },
    });
  };

  render() {
    return (
      <div className="home">
        <SideBar />
        <div className="toast_container">
          {this.state.toast_data.map((item) => {
            return (
              item !== null && (
                <Toast
                  animation
                  className={item.class || "unknown"}
                  show={this.state.showToast[item.idx]}
                  onClose={() => this.close(item.idx, item.pos)}
                  autohide
                  delay={5000}
                >
                  <Toast.Header>
                    <strong className={"mr-auto "}>
                      {item.header || "Unknown"}
                    </strong>
                    <small>{getTime()}</small>
                  </Toast.Header>
                  <Toast.Body>
                    {item.msg ||
                      "Some undocummented error occured. Please contact the administrator to look at this problem"}
                  </Toast.Body>
                </Toast>
              )
            );
          })}
        </div>
        <Modal show={this.state.showModal} onHide={this.move} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>Sukses!!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Data admin <b>{this.state.modalData.name}</b> telah berhasil{" "}
              {this.state.modalData.type === "input"
                ? "didaftarkan"
                : "diupdate"}
              .
            </p>
            {this.state.modalData.type === "input" && (
              <>
                <p>
                  Password yang dibuat oleh server:{" "}
                  <b>{this.state.modalData.uniq}</b>
                </p>
                <p>
                  <b>
                    Jangan memberitahukan password kepada siapapun kecuali orang
                    yang bersangkutan
                  </b>
                </p>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.move}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="main">
          <FormAdmin {...this.props} generateToast={this.generateToast} showModal={this.showModal} />
        </div>
      </div>
    );
  }
}

export default FormAdminMasterAset;
