import { Button, Form, Image, Col, Spinner } from "react-bootstrap";
import React, { Component } from "react";
import "../styles/Colour_Style.css";
import "../styles/Form_Style.css";
import { useForm, ErrorMessage } from "react-hook-form";
import { useState, useEffect } from "react";
import {
  condensingWhitespace,
  handleNumberInput,
  handleTextInput
} from "../logic/FormInput_Logic";
import { admin,branch } from "../logic/handler";

function FormAdminFunCom(props) {
  const max = 2097152;
  const {
    register,
    errors,
    handleSubmit,
    clearError,
    setError,
    setValue,
    getValues
  } = useForm({
    mode: "all",
    validateCriteriaMode: "all",
    defaultValues: {
      name: props.location.state ? props.location.state.name : "",
      hp: props.location.state ? props.location.state.hp : "",
      email: props.location.state ? props.location.state.email : "",
      nik: props.location.state ? props.location.state.nik : "",
      username: props.location.state ? props.location.state.username : "",
      branch_id: props.location.state ? props.location.state.branch_id._id : "",
    }
  });

  const [state, setState] = useState({
    image: props.location.state ? props.location.state.ktp : "assets/image.png",
    file: null,
    loading: false,
    imgPath: null,
    edit: props.location.state ? true : false,
    branch: null,
  });

  

  const inputImage = event => {
    // let objFormData = new FormData();
    // objFormData.append("image", event.target.files[0]);
    setState({
      ...state,
      image: URL.createObjectURL(event.target.files[0]),
      file: event.target.files[0],
      imgPath: event.target.files[0].name
    });
  };

  const validateImage = value => {
    return value[0] ? value[0].size <= max : state.edit;
  };

  const handleChange = event => {
    let val = "";
    if (event.target.name === "hp" || event.target.name === "nik")
      val = handleNumberInput(event);
    else val = handleTextInput(event.target.name, event);
    setValue(event.target.name, val);
  };

  const matchPass = () => {
    clearError("missmatch");
    if (getValues("password") === getValues("confirm_pass")) {
      clearError("confirm_pass");
      return true;
    } else {
      setError(
        "missmatch",
        "notMatch",
        "Password konfirmasi tidak sesuai dengan password masukan"
      );
      return false;
    }
  };

  const validatePass = value => {
    clearError("req");
    if (value === "") {
      clearError("req");
      return true;
    } else if (value.length < 8) {
      setError(
        "req",
        "minLength",
        "Password tidak boleh kurang dari 8 karakter atau harus kosong"
      );
      return false;
    } else return true;
  };

  const submit = async value => {
    setState({
      ...state,
      loading: true
    });
    value = condensingWhitespace(value);
    let reqFormData = new FormData();
    for (const key in value) {
      if (key === "imgPhoto") {
        if (!state.edit || (state.edit && value.imgPhoto.length > 0))
          reqFormData.append("ktp", state.file);
      } else reqFormData.append(key, value[key]);
    }
    let success = false;
    let resp = await admin(
      localStorage.getItem("token"),
      reqFormData,
      state.edit ? "put" : "post",
      state.edit ? props.location.state._id : null
    );
    console.log(resp);
    if (resp[0]) {
      if (resp[0].status === 200) {
        success = true;
      } else {
      }
    } else {
      if (Array.isArray(resp[1].data)) {
        for (const key in resp[1].data) {
          props.generateToast(
            1,
            "Status " + resp[1].status + ": " + resp[1].data[key].msg
          );
        }
      }
    }
    setState({
      ...state,
      loading: false
    });
    if (success) {
      state.edit
        ? props.showModal("edit", resp[0].data.name, resp[0].message)
        : props.showModal("input", resp[0].data.name, resp[0].data.password);
    }
  };

  useEffect(() => {

  (async () => {
   setState({
        ...state,
        loading: true
       })
     let resp = await branch(localStorage.getItem("token"), null, "get");
     if(resp[0] && resp[0].status === 200){
       setState({
         ...state,
         branch: resp[0].data,
        loading: false
       })
       setValue("branch_id", props.location.state ? props.location.state.branch_id._id : "");
     }
  })();

}, []);

  return (
    <div className="container">
      {state.edit && (
        <h4>
          Sedang mengedit informasi dari admin: {props.location.state.name}
        </h4>
      )}
      <Form className="form" onSubmit={handleSubmit(submit)}>
        <Form.Group controlId="formBasicName">
          <Form.Label>Nama :</Form.Label>
          <Form.Control
            className={errors.name && "invalid"}
            name="name"
            type="text"
            ref={register({ required: "Input tidak boleh kosong" })}
            onChange={handleChange}
          />
          <ErrorMessage errors={errors} name="name">
            {({ message }) => <p className="error_info">{message}</p>}
          </ErrorMessage>
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col} controlId="formBasicHP">
            <Form.Label for="phone">No HP :</Form.Label>
            <Form.Control
              className={errors.hp && "invalid"}
              type="tel"
              name="hp"
              ref={register({
                required: "Input tidak boleh kosong",
                pattern: {
                  value: /^[+-]?\d+$/,
                  message:
                    "Format salah, contoh yang benar: +6221222333 / 021222333"
                },
                maxLength: {
                  value: 16,
                  message: "Nomor terlalu panjang, maksimal 16 digit"
                },
                minLength: {
                  value: 8,
                  message: "Nomor terlalu pendek, minimal 8 digit"
                }
              })}
              onChange={handleChange}
            />
            <ErrorMessage errors={errors} name="hp">
              {({ message }) => <p className="error_info">{message}</p>}
            </ErrorMessage>
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicEmail">
            <Form.Label>Email :</Form.Label>
            <Form.Control
              className={errors.email && "invalid"}
              type="email"
              name="email"
              ref={register({
                required: "Input tidak boleh kosong",
                pattern: {
                  value: /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/,
                  message:
                    "Tipe e-mail yang dimasukan belum sesuai. Contoh yang benar: JohnDoe@gmail.com"
                }
              })}
              onChange={handleChange}
            />
            <ErrorMessage errors={errors} name="email">
              {({ message }) => <p className="error_info">{message}</p>}
            </ErrorMessage>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="formBasicNIK">
            <Form.Label>No NIK :</Form.Label>
            <Form.Control
              className={errors.nik && "invalid"}
              name="nik"
              type="text"
              maxLength="16"
              ref={register({
                required: "Input tidak boleh kosong",
                minLength: {
                  value: 16,
                  message: "Input tidak boleh kurang dari 16 digit"
                }
              })}
              onChange={handleChange}
            />
            <ErrorMessage errors={errors} name="nik">
              {({ message }) => <p className="error_info">{message}</p>}
            </ErrorMessage>
          </Form.Group>
        </Form.Row>
        <Form.Group controlId="formBasicUsername">
          <Form.Label>Username :</Form.Label>
          <Form.Control
            className={errors.username && "invalid"}
            type="text"
            name="username"
            ref={register({ required: "Input tidak boleh kosong" })} //berapa min - max
            onChange={handleChange}
          />
          <ErrorMessage errors={errors} name="username">
            {({ message }) => <p className="error_info">{message}</p>}
          </ErrorMessage>
        </Form.Group>

        <Form.Group>
          <Form.Label>
            Cabang :{" "}
            {state.loading && (
              <Spinner variant="primary" animation="border" />
            )}
          </Form.Label>
          <Form.Control
            className={errors.branch_id && "invalid"}
            as="select"
            name="branch_id"
            //disabled={perusahaan_pemilik.loading}
            //onClick={handleClick}
            ref={register({ required: "Input tidak boleh kosong" })}
          >
            <option value="">Pilih Cabang untuk Admin</option>
            {state.branch &&
              state.branch.map((item, key) => (
                <option name="branch_id" value={item._id} key={item._id}>
                  {item.name}
                </option>
              ))}
          </Form.Control>
          <ErrorMessage errors={errors} name="branch_id">
            {({ message }) => <p className="error_info">{message}</p>}
          </ErrorMessage>
        </Form.Group>

        {state.edit && (
          <Form.Row>
            <Form.Group as={Col} controlId="formBasicPassword">
              <Form.Label>
                New Password :<em> (Opsional)</em>
              </Form.Label>
              <Form.Control
                className={errors.password && "invalid"}
                type="Password"
                name="password"
                ref={register({
                  validate: value => validatePass(value)
                })}
              />
              <ErrorMessage errors={errors} name="req">
                {({ message }) => <p className="error_info">{message}</p>}
              </ErrorMessage>
            </Form.Group>
            <Form.Group as={Col} controlId="formBasicConfirmPassword">
              <Form.Label>
                Confirm Password :<em> (Opsional)</em>
              </Form.Label>
              <Form.Control
                className={errors.confirm_pass && "invalid"}
                type="Password"
                name="confirm_pass"
                ref={register({
                  validate: {
                    notMatch: value => matchPass(value)
                  }
                })}
              />
              <ErrorMessage errors={errors} name="missmatch">
                {({ message }) => <p className="error_info">{message}</p>}
              </ErrorMessage>
            </Form.Group>
          </Form.Row>
        )}
        <div className="container-image">
          <div className="form-image">
            <div className="input-group">
              <div className="custom-file">
                <label
                  className={
                    errors.imgPhoto
                      ? "custom-file-label invalid"
                      : "custom-file-label"
                  }
                  htmlFor="inputGroupFile01"
                >
                  {state.imgPath ? state.imgPath : "Pilih Foto KTP"}
                </label>
                <input
                  accept="image/*"
                  type="file"
                  className="custom-file-input"
                  name="imgPhoto"
                  onChange={inputImage}
                  ref={
                    !state.edit
                      ? register({
                          required: true,
                          validate: value =>
                            validateImage(value) ||
                            "Gambar yang anda masukkan melebihi batas maksimal (2MB)"
                        })
                      : register({
                          validate: value =>
                            validateImage(value) ||
                            "Gambar yang anda masukkan melebihi batas maksimal (2MB)"
                        })
                  }
                />
              </div>
            </div>
            <div className="btn-image">
              <Image
                src={state.image}
                style={{
                  width: "10rem",
                  height: "10rem",
                  marginBottom: 15,
                  alignSelf: "center"
                }}
              />
              <p>KTP</p>
            </div>
          </div>
        </div>
        <ErrorMessage errors={errors} name="imgPhoto">
          {({ message }) => <p className="error_info">{message}</p>}
        </ErrorMessage>
        <div className="btn-container">
          <Button variant="primary" type="submit" disabled={state.loading}>
            {state.loading ? (
              <Spinner animation="border" variant="secondary" />
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default FormAdminFunCom;