import React, { Component } from "react";
import ReactDOM from "react-dom";
import { useRef } from "react";
import { Badge, Button, Modal, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import SideBar from "../components/SideBar_Com";
import Table from "../components/Table_Com";
import { aset } from "../logic/handler";
import "../styles/Table_Style.css";
import Timeout from "./Timeout_Page";
import ServerError from "./ServerError_Page";
import moment from "moment";
import * as jsPDF from "jspdf";
import "jspdf-autotable";
import _ from "lodash";

class TableAdminAset extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      data: {},
      modal: false,
      delLoading: false,
      delSuccess: null,
      error: null,
    };
    this.month = {
      0: "Januari",
      1: "Februari",
      2: "Maret",
      3: "April",
      4: "Mei",
      5: "Juni",
      6: "Juli",
      7: "Agustus",
      8: "September",
      9: "Oktober",
      10: "November",
      11: "Desember",
    };
    this.$ = props.dt;
    this.detailRows = [];
  }

  componentDidMount() {
    this.$el = this.$(this.tableRef.current);
    var componentInstance = this;
    let dataTable = this.$el.DataTable({
      serverSide: false,
      scrollY: 341,
      scrollCollapse: true,
      processing: true,
      ajax: async (data, callback, settings) => {
        let resp = await aset(localStorage.getItem("token"), null, "get");
        let dataInject = [];
        if (resp[0]) {
          if (resp[0].status === 200) {
            dataInject = resp[0].data;
            let i = 1;
            for (var obj in dataInject) {
              obj = {
                ...dataInject[obj],
                no: i,
              };
              dataInject[i - 1] = obj;
              i++;
            }
          }
          callback({
            data: dataInject,
          });
        } else {
          this.setState({
            error: resp[1].status,
          });
          return;
        }
      },
      columns: [
        {
          data: "no",
        },
        {
          data: "goods_type",
        },
        {
          data: "description",
        },
        {
          data: "issue_date",
          render: (data, type, row, meta) => {
            let date = new Date(data);
            if (type === "display" || type === "filter") {
              return (
                date.getDate() +
                " " +
                this.month[date.getMonth()] +
                " " +
                date.getFullYear()
              );
            } else return date.getTime() / 1000;
          },
        },
        {
          data: "expired_date",
          render: (data, type, row, meta) => {
            let date = new Date(data);
            if (type === "display" || type === "filter") {
              return (
                date.getDate() +
                " " +
                this.month[date.getMonth()] +
                " " +
                date.getFullYear()
              );
            } else return date.getTime() / 1000;
          },
        },
        {
          className: "exp-container",
          data: "status_expired",
          render: (data, type, row, meta) => {
            if (type === "filter" || type === "display") {
              return data ? "true" : "false";
            }
            return data ? 0 : 1;
          },
        },
        {
          data: "empty",
          render: (data, type, row, meta) => {
            return (
              '<div class="icon_container"><img class="icon edit" key="' +
              data +
              '" src="assets/edit.svg"/>' +
              '<img class="icon delete" key="' +
              data +
              '" src="assets/trash.svg"/>' +
              "</div>"
            );
          },
          orderable: false,
        },
        {
          className: "details-control",
          orderable: false,
          data: null,
          defaultContent: "",
        },
      ],
      drawCallback: function () {
        const promise = new Promise((resolve, reject) => {
          //handle if user switch page too fast
          try {
            var api = this.api();
            var rowCount = api.rows({ page: "current" }).count();
            let td = "<td>&nbsp;</td>";
            for (let i = 0; i < api.columns()[0].length - 1; i++) {
              td = td + "<td></td>";
            }
            let rowType = rowCount % 2 === 1 ? "even" : "odd";
            for (
              var i = 0;
              i < api.page.len() - (rowCount === 0 ? 1 : rowCount);
              i++
            ) {
              componentInstance
                .$("#data_table tbody")
                .append(
                  componentInstance.$(
                    "<tr class='" + rowType + "' role='row'>" + td + "</tr>"
                  )
                );
              rowType = rowType === "odd" ? "even" : "odd";
            }
            resolve(true);
          } catch (err) {
            reject(err);
          }
        });
        promise.then(
          (resolve) => {
            return;
          },
          (reject) => {
            return;
          }
        );
      },
      order: [[0, "asc"]],
      columnDefs: [
        {
          targets: 7,
          createdCell: (td, cellData, rowData, row, col) => {
            ReactDOM.render(
              <img
                className="row-info-detail"
                src="assets/info-circle-solid.svg"
              />,
              td
            );
          },
        },
        {
          targets: 5,
          createdCell: (td, cellData, rowData, row, col) => {
            let variant = cellData === true ? "danger" : "success";
            let innerText = cellData === true ? "EXPIRED" : "ACTIVE";
            ReactDOM.render(
              <Badge className="exp-status" variant={variant}>
                {innerText}
              </Badge>,
              td
            );
          },
        },
      ],
    });
    dataTable.draw();
    dataTable.on("click", "img.edit", function () {
      let rowData = dataTable
        .row(componentInstance.$(this).parents("tr"))
        .data();
      console.log(rowData);
      componentInstance.props.history.push({
        pathname: "/FormDataAset",
        state: rowData,
      });
    });
    dataTable.on("click", "img.delete", function () {
      let rowData = dataTable
        .row(componentInstance.$(this).parents("tr"))
        .data();
      componentInstance.preview(rowData, "delete");
    });

    //this code below, untill the next comment is copied and edited from
    //https://datatables.net/examples/server_side/row_details.html
    dataTable.on("click", "tr td.details-control", function () {
      let tr = componentInstance.$(this).closest("tr");
      let row = dataTable.row(tr);
      let idx = componentInstance.$.inArray(
        tr.attr("id"),
        componentInstance.detailRows
      );
      let desc = row.data().no_certificate;
      let imgComp = componentInstance.$(tr[0].firstChild.firstChild);
      let isOdd = tr[0].className.split(" ").includes("odd");

      if (row.child.isShown()) {
        tr.removeClass("details");
        row.child.hide();
        componentInstance.detailRows.splice(idx, 1);
        imgComp.attr("src", "assets/info-circle-solid.svg");
      } else {
        tr.addClass("details");
        let clnm = isOdd ? "row-detail odd" : "row-detail even";
        console.log(clnm);
        row
          .child(
            "<p class='desc'>No. Surat/Sertifikasi: " + desc + "</p>",
            clnm
          )
          .show();
        imgComp.attr("src", "assets/times-circle-solid.svg");
        if (idx === -1) {
          componentInstance.detailRows.push(tr.attr("id"));
        }
      }
    });
    componentInstance.$("#data_table_info").appendTo(".info_paging_container");
    componentInstance
      .$("#data_table_paginate")
      .appendTo(".info_paging_container");
    dataTable.on("draw", function () {
      componentInstance.$.each(componentInstance.detailRows, function (i, id) {
        componentInstance.$("#" + id + " td.details-control").trigger("click");
      });
    });
    //next comment
  }

  preview = (data, type) =>
    this.setState({ modal: true, data: data, type: type });

  prevModal = () =>
    this.setState({ modal: !this.state.modal }, () =>
      this.setState({ delSuccess: null })
    );

  print = () => {
    const dataTable = this.$("#data_table").DataTable();
    var print_data = [];
    var index_tabel = 1;
    dataTable.rows({ search: "applied" }).every(function (index) {
      var row = dataTable.row(index);
      var data = row.data();
      print_data.push([
        index_tabel,
        data.goods_type,
        data.no_certificate,
        data.description,
        moment(data.issue_date).format("L"),
        moment(data.expired_date).format("L"),
        data.status_expired ? "Expired" : "Active",
      ]);
      index_tabel = index_tabel + 1;
    });
    console.log(print_data);
    this.constructPDF(print_data);
  };

  constructPDF = (data) => {
    var nota = new jsPDF({
      orientation: "landscape",
      format: "a4",
      unit: "pt",
    });

    const pages = nota.internal.getNumberOfPages();
    const pageWidth = nota.internal.pageSize.width; //Optional
    const pageHeight = nota.internal.pageSize.height; //Optional
    const desireTableWidth = 690; //acquired from total jspdf-autotable column width
    const margin = (pageWidth - desireTableWidth) / 2;

    nota.setFontSize(18);
    nota.text("Daftar Aset PT. MIGAS KUKAR MANDIRI", pageWidth / 2, 40, {
      align: "center",
    });
    nota.setFontSize(12);
    nota.autoTable({
      head: [
        [
          "No",
          "Jenis Barang",
          "No Sertifikat",
          "Deskripsi",
          "Tanngal Terbit",
          "Tanngal Expired",
          "Status",
        ],
      ],
      body: data,
      startY: 55,
      theme: "grid",
      margin: { left: margin, right: margin },
      styles: {
        lineColor: [0, 0, 0],
        lineWidth: 1,
        fontSize: 8,
        minCellHeight: 20,
      },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        halign: "center",
        valign: "middle",
        minCellHeight: 0,
      },
      bodyStyles: {
        halign: "center",
        cellWidth: "wrap",
      },
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 130 },
        2: { cellWidth: 130 },
        3: { cellWidth: 200, halign: "left" },
        4: { cellWidth: 75 },
        5: { cellWidth: 75 }, //total of 690 pt
        6: { cellWidth: 50 },
      },
    });
    nota.setFontSize(10); //Optional

    for (let j = 1; j < pages + 1; j++) {
      let horizontalPos = pageWidth / 2; //Can be fixed number
      let verticalPos = pageHeight - 15; //Can be fixed number
      nota.setPage(j);
      //nota.text(`${j} of ${pages}`, horizontalPos, verticalPos, {align: 'center'  //Optional text styling});
      nota.text(`Halaman ${j} / ${pages}`, horizontalPos, verticalPos, {
        align: "center",
      });
    }
    nota.autoPrint();
    window.open(nota.output("bloburl"));
  };

  delete = async () => {
    this.setState({
      delLoading: true,
    });
    let success = false;
    let resp = await aset(
      localStorage.getItem("token"),
      this.state.data,
      "delete"
    );
    if (resp[0]) {
      if (resp[0].status === 200) {
        success = true;
      } else {
      }
    } else {
    }
    this.setState({
      delLoading: false,
      delSuccess: success,
    });
  };

  render() {
    return (
      <div className="home">
        <Modal
          backdrop={this.state.delLoading ? "static" : true}
          show={this.state.modal}
          onHide={
            this.state.delSuccess
              ? () => window.location.reload()
              : this.prevModal
          }
        >
          <Modal.Header closeButton={!this.state.delLoading}>
            Konfirmasi
          </Modal.Header>
          <Modal.Body>
            {this.state.delSuccess === null ? (
              <div>
                <p>Apakah anda yakin untuk menghapus data admin berikut ini:</p>
                <ul>
                  <li>No. Sertifikat: {this.state.data.no_certificate}</li>
                  <li>Jenis Baraang : {this.state.data.goods_type}</li>
                  <li>Deskripsi : {this.state.data.description}</li>
                  <li>Kontak : {this.state.data.contact}</li>
                </ul>
              </div>
            ) : this.state.delSuccess ? (
              <p>
                Data aset dengan No. Sertifikat{" "}
                <b>{this.state.data.no_certificate}</b> telah berhasil dihapus
              </p>
            ) : (
              <>
                <p>
                  Perhatian: terjadi kesalahan ketika sedang mencoba menghapus
                  data aset.
                </p>
                <p>
                  Silahkan coba beberapa saat lagi, atau hubungi admin bila
                  masalah masih berlangsung.
                </p>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            {
              <>
                {this.state.delSuccess === null && (
                  <Button
                    onClick={this.prevModal}
                    disabled={this.state.delLoading}
                  >
                    Batalkan
                  </Button>
                )}
                <Button
                  onClick={
                    this.state.delSuccess === null
                      ? this.delete
                      : this.state.delSuccess
                      ? () => window.location.reload()
                      : this.prevModal
                  }
                  variant="secondary"
                  disabled={this.state.delLoading}
                >
                  {this.state.delSuccess === null ? (
                    this.state.delLoading ? (
                      <Spinner variant="secondary" animation="border" />
                    ) : (
                      "Ya"
                    )
                  ) : (
                    "Tutup"
                  )}
                </Button>
              </>
            }
          </Modal.Footer>
        </Modal>
        <SideBar />
        <div className="main">
          {!this.state.error ? (
            <>
              <div className="container-table">
                <Table type="Aset" childRef={this.tableRef} />
                <div id="contPagi" className="container-pagination">
                  <div className="dataTables_wrapper table_footer">
                    <div className="info_paging_container"></div>
                  </div>
                </div>
                <div id="admin-aset" className="tambah-print-btn">
                  <Link to="/FormDataAset">
                    <Button className="btn-admin-asset" variant="primary" >Tambah</Button>
                  </Link>
                  <Button className="btn-admin-asset" variant="secondary" onClick={() => this.print()}>Print</Button>
                </div>
              </div>
            </>
          ) : this.state.error === 408 ? (
            <Timeout />
          ) : (
            <ServerError />
          )}
        </div>
      </div>
    );
  }
}

export default TableAdminAset;
