import React, { useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LoginForm from "../src/pages/Login_Page";
import GantiPassword from "./pages/GantiPassword_Page";

/*Bagian Master Aset*/
import FormAdminMasterAset from "../src/pages/FormAdminMasterAset_Page";
import DetailDataMasterAset from "../src/pages/DetailDataMasterAset_Page";
import TableMasterAset from "../src/pages/TableMasterAset_Page";
import TableAdminMasterAset from "../src/pages/TableAdminMasterAset_Page";
import TableLog from "../src/pages/TableLog_Page";

import FormBranchMasterAset from "../src/pages/FormBranchMasterAset_Page";
import TableBranchMasterAset from "../src/pages/TableBranchMasterAset_Page";

/*Bagian Admin Kota Aset */
import FormDataAset from "../src/pages/FormDataAset_Page";
import TableAdminAset from "../src/pages/TableAdminAset_Page";
import PrivateRoute from "./PrivateRoute";
import BadRequest from "./pages/BadRequest_Page";
import Unauthorized from "./pages/Unauthorized_Page";

import "bootstrap/dist/css/bootstrap.min.css";

const $ = require("jquery");
$.Datatable = require("datatables.net");

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={LoginForm} />
        <Route exact path="/GantiPassword" component={GantiPassword} />

        {/*Bagian Master Aset */}
        <PrivateRoute
          exact
          path="/DetailDataMasterAset"
          component={DetailDataMasterAset}
          dt={$}
          level={[2]}
          //render={(props) => <DetailDataMasterAset {...props}/>}
        />
        <PrivateRoute
          exact
          path="/FormAdminMasterAset"
          component={FormAdminMasterAset}
          dt={$}
          level={[2]}
          //render={(props) => <FormAdminMasterAset {...props}/>}
        />
        <PrivateRoute
          exact
          path="/FormBranchMasterAset"
          component={FormBranchMasterAset}
          dt={$}
          level={[2]}
          //render={(props) => <FormAdminMasterAset {...props}/>}
        />
        <PrivateRoute
          exact
          path="/TableMasterAset"
          component={TableMasterAset}
          dt={$}
          level={[2]}
          //render={(props) => <TableMasterAset {...props} dt={$} />}
        />
        <PrivateRoute
          exact
          path="/TableAdminMasterAset"
          component={TableAdminMasterAset}
          dt={$}
          level={[2]}
          //render={(props) => <TableAdminMasterAset {...props} dt={$} />}
        />
        <PrivateRoute
          exact
          path="/TableBranchMasterAset"
          component={TableBranchMasterAset}
          dt={$}
          level={[2]}
          //render={(props) => <TableAdminMasterAset {...props} dt={$} />}
        />
        <PrivateRoute 
          exact 
          path="/TableLog" 
          component={TableLog}
          dt={$}
          level={[2]}
          //render={(props) => <TableLog {...props} dt={$} />}
        />
        <PrivateRoute
          exact
          path="/TableAdminAset"
          component={TableAdminAset}
          dt={$}
          level={[1]}
          //render={(props) => <TableAdminAset {...props} dt={$} />}
        />
        <PrivateRoute
          exact
          path="/FormDataAset"
          component={FormDataAset}
          dt={$}
          level={[1]}
          // render={(props) => <FormDataAset {...props} dt={$} />}
        />
        <Route path="*" component={BadRequest}/>
      </Switch>
    </Router>
  );
}

export default App;
