import React, {Component} from "react"
import {Image, Row, Col} from "react-bootstrap"
import '../styles/Colour_Style.css'
import '../styles/Form_Style.css'

class BadRequest extends Component{
    render(){
        return(
            <div>
                <div className="unauthorize-container">
                    <Row>
                        <Col id="logo-unauthorized" sm={4}>
                            <Image src="assets/logo/mkm-transparent.png"/>
                        </Col>
                        <Col sm={7} style={{display:"flex", justifyContent:"center", alignItems:"center", marginLeft:"20px"}}>
                            <h1 style={{fontSize:"5vh"}}>404<br/>Bad Request</h1>
                            <h2 style={{fontSize:"3vh"}}>Anda mengakses URL yang tidak terdaftar atau mencoba mengakses halaman tidak sesuai prosedur.</h2>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}
export default BadRequest;