import { Form, Col, Row, Button, Image } from "react-bootstrap";
import React, { Component } from "react";
import "../styles/Colour_Style.css";
import "../styles/Form_Style.css";
class DetailDataAsetCom extends Component {
  constructor(props) {
    super(props);
    if (!props.location.state) {
      props.history.replace("/400BadRequest");
      return;
    }
    this.no = props.location.state.no;
    this.goods_type = props.location.state.goods_type;
    this.description = props.location.state.description;
    this.issue_date = new Date(props.location.state.issue_date);
    this.expire_date = new Date(props.location.state.expired_date);
    this.certificate = props.location.state.no_certificate;
    this.service = props.location.state.service_name;
    this.cost = props.location.state.cost;
    this.contact = props.location.state.contact;
    this.memo = props.location.state.memo;

    this.month = {
      0: "Januari",
      1: "Februari",
      2: "Maret",
      3: "April",
      4: "Mei",
      5: "Juni",
      6: "Juli",
      7: "Agustus",
      8: "September",
      9: "Oktober",
      10: "Novebmer",
      11: "Desember",
    };
  }

  formatDateDisplay = (val) => {
    let date = val.getDate() + 1;
    let month = this.month[val.getMonth()];
    let year = val.getFullYear();
    return date + " " + month + ", " + year;
  };

  render() {
    return (
      <div className="container">
        <div className="page">
          <Form>
            <Form.Row style={{ borderBottom: "2px solid #f47121" }}>
              <Col xs={12} md={2} lg={2}>
                <Image
                  className="logo-mkm-pengiriman"
                  src="assets/logo/mkm-transparent.png"
                />
              </Col>
              <Col xs={12} md={10} lg={8}>
                <h3 className="detail-aset" style={{ color: "#f47121" }}>
                  PT. MIGAS KUKAR MANDIRI
                </h3>
                <h5 className="detail-aset">Detail Data Aset</h5>
                <h6 className="detail-aset">No : {this.no}</h6>
              </Col>
            </Form.Row>
            <Form.Row className="jenis-barang">
              <h6>
                Jenis Barang :{" "}
                <span style={{ fontWeight: "bolder" }}>{this.goods_type}</span>
              </h6>
            </Form.Row>
            <Form.Group as={Row} className="detail-aset-row">
              <Col sm={2} className="detail-aset-form">
                <Form.Label>Deskripsi</Form.Label>
              </Col>
              <Col sm={10}>
                <Form.Control
                  plaintext
                  readOnly
                  defaultValue={this.description}
                  className="detail-aset-text"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="detail-aset-row">
              <Col sm={2} className="detail-aset-form">
                <Form.Label>Tgl. Terbit</Form.Label>
              </Col>
              <Col sm={10}>
                <Form.Control
                  plaintext
                  readOnly
                  defaultValue={this.formatDateDisplay(this.issue_date)}
                  className="detail-aset-text"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="detail-aset-row">
              <Col sm={2} className="detail-aset-form">
                <Form.Label>Tgl. Expired</Form.Label>
              </Col>
              <Col sm={10}>
                <Form.Control
                  plaintext
                  readOnly
                  defaultValue={this.formatDateDisplay(this.expire_date)}
                  className="detail-aset-text"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="detail-aset-row">
              <Col xs={12} md={2} className="detail-aset-form">
                <Form.Label>No Surat</Form.Label>
              </Col>
              <Col xs={12} md={4}>
                <Form.Control
                  plaintext
                  readOnly
                  defaultValue={this.certificate}
                  className="detail-aset-text"
                />
              </Col>
              <Col
                xs={12} md={2}
                className="detail-aset-form nama-jasa"
              >
                <Form.Label>Nama Jasa</Form.Label>
              </Col>
              <Col xs={12} md={4}>
                <Form.Control
                  plaintext
                  readOnly
                  defaultValue={this.service}
                  className="detail-aset-text"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="detail-aset-row">
              <Col xs={12} xl={2} className="detail-aset-form">
                <Form.Label>Biaya</Form.Label>
              </Col>
              <Col xs={3} xl={1} style={{ paddingRight: 0 }}>
                <Form.Control
                  plaintext
                  readOnly
                  defaultValue="Rp"
                  className="detail-aset-text"
                ></Form.Control>
              </Col>
              <Col xs={9} xl={9}>
                <Form.Control
                  plaintext
                  readOnly
                  defaultValue={this.cost}
                  className="detail-aset-text"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="detail-aset-row">
              <Col sm={2} className="detail-aset-form">
                <Form.Label>Memo</Form.Label>
              </Col>
              <Col sm={10}>
                <Form.Control
                  as="textarea"
                  plaintext
                  readOnly
                  defaultValue={this.memo}
                  className="detail-aset-text"
                />
              </Col>
            </Form.Group>
            <Form.Group
              style={{ borderTop: "2px solid #f47121", marginTop: "10px" }}
              as={Row}
            >
              <Form.Row>
                <Col className="detail-aset-form" style={{ marginTop: "20px" }}>
                  <Form.Label>
                    Contact Person : <strong>{this.contact}</strong>
                  </Form.Label>
                </Col>
              </Form.Row>
            </Form.Group>
          </Form>
        </div>
        <Row className="btn-container">
          <Button
            variant="primary"
            type="submit"
            onClick={() => this.props.history.push("/TableMasterAset")}
          >
            Back
          </Button>
        </Row>
      </div>
    );
  }
}

export default DetailDataAsetCom;
