import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { Image } from "react-bootstrap";
import "../styles/Sidebar_Style.css";
import "../styles/Colour_Style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faClipboard,
  faSignOutAlt,
  faUnlockAlt,
  faDatabase,
} from "@fortawesome/free-solid-svg-icons";

class SideBar extends Component {
  onPaths = (paths) => {
    return (match, location) => {
      return paths.includes(location.pathname);
    };
  };

  render() {
    return (
      <>
        <div className="sidenav">
          <div className="logo-container">
            <Image
              id="logo-sidebar"
              className="logo-mkm"
              src="assets/logo/mkm-transparent.png"
            />
          </div>

          {localStorage.getItem("isAdminMaster") === "true" ? ( //admin master sidebar
            <>
              <NavLink
                activeClassName="active-link"
                exact
                isActive={this.onPaths([
                  "/TableMasterAset",
                  "/DetailDataMasterAset",
                ])}
                to="/TableMasterAset"
              >
                <FontAwesomeIcon className="icon-sidebar" icon={faDatabase} />
                Data Aset
              </NavLink>
              <NavLink
                activeClassName="active-link"
                isActive={this.onPaths([
                  "/TableAdminMasterAset",
                  "/FormAdminMasterAset",
                ])}
                exact
                to="/TableAdminMasterAset"
              >
                <FontAwesomeIcon className="icon-sidebar" icon={faUser} />
                Admin
              </NavLink>
              <NavLink
                activeClassName="active-link"
                isActive={this.onPaths([
                  "/TableBranchMasterAset",
                  "/FormBranchMasterAset",
                ])}
                exact
                to="/TableBranchMasterAset"
              >
                <FontAwesomeIcon className="icon-sidebar" icon={faUser} />
                Cabang
              </NavLink>
              <NavLink activeClassName="active-link" exact to="/TableLog">
                <FontAwesomeIcon className="icon-sidebar" icon={faClipboard} />
                Log
              </NavLink>
            </>
          ) : ( //admin kota sidebar
            <NavLink
              activeClassName="active-link"
              exact
              isActive={this.onPaths(["/TableAdminAset", "/FormDataAset"])}
              to="/TableAdminAset"
            >
              <FontAwesomeIcon className="icon-sidebar" icon={faDatabase} />
              Data Aset
            </NavLink>
          )}
          <div className="btn-logout-container">
            <div className="button-bottom-container">
              <NavLink exact activeClassName="active-link" to="/GantiPassword">
                <FontAwesomeIcon className="icon-sidebar" icon={faUnlockAlt} />
                Ganti Password
              </NavLink>
              <NavLink exact to="/">
                <FontAwesomeIcon className="icon-sidebar" icon={faSignOutAlt} />
                Keluar
              </NavLink>
            </div>
            <div className="copyright-container">
              PT.Migas Kukar Mandiri&copy;{new Date().getFullYear()}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(SideBar);
