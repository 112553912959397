import Axios from "axios";

const useData = ["post", "put"];
const timeout = 30000;
const timeoutErrorMessage =
  "Request Time Out: proses telah melebihi 30 detik. Silahkan coba lagi.";

const handleError = (error) => {
  let errorResponse = null;
  if (error.code === "ECONNABORTED") {
    errorResponse = {
      status: 408,
      error: [{ msg: error.message }],
      message: "Request Time Out",
    };
  } else if (error.response) {
    errorResponse = error.response.data;
  } else {
    //bisa karena gada error response, etc
    errorResponse = {
      status: 501,
      error: [{ msg: "Server Implementation error." }],
    };
  }
  return errorResponse;
};

const hitAPI = async (param) => {
  var response,
    error = null;
  await Axios({
    ...param,
  })
    .then((r) => {
      response = r.data;
    })
    .catch((e) => {
      error = handleError(e);
    });
  return [response, error];
};

export const auth = async (username, password) => {
  let param = {
    method: "post",
    headers: { "Content-Type": "application/json" },
    baseURL: process.env.REACT_APP_API_URL,
    url: process.env.REACT_APP_API_LOGIN,
    data: {
      username: username,
      password: password,
    },
    timeout: timeout,
    timeoutErrorMessage: timeoutErrorMessage,
  };
  return await hitAPI(param);
};

export const aset = async (token, value, method) => {
  var url = null;
  let data = {};
  switch (method) {
    case "put":
      url = process.env.REACT_APP_API_ASET + "update/" + value._id;
      break;
    case "delete":
      url = process.env.REACT_APP_API_ASET + "/delete/" + value._id;
      break;
    case "detail":
      url = process.env.REACT_APP_API_ASET + "/" + value._id;
      method = "get";
    case "expired":
      url = process.env.REACT_APP_API_ASET + "/expired";
      method = "get";
      break;
    default:
      //get or post
      url = process.env.REACT_APP_API_ASET;
      break;
  }
  if (method === "put" || method === "post") {
    data = {
      goods_type: value.goods_type,
      description: value.description,
      issue_date: value.issue_date,
      expired_date: value.expired_date,
      no_certificate: value.no_certificate,
      cost: value.cost,
      service_name: value.service_name,
      contact: value.contact,
      memo: value.memo,
    };
  }
  console.log(data)
  let param = {};
  param.method = method;
  param.baseURL = process.env.REACT_APP_API_URL;
  param.url = url;
  param.headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  param.timeout = timeout;
  param.timeoutErrorMessage = timeoutErrorMessage;
  if (useData.includes(method)) param.data = data;

  return await hitAPI(param);
};

export const log = async (token) => {
  let param = {
    method: "get",
    baseURL: process.env.REACT_APP_API_URL,
    url: process.env.REACT_APP_API_LOG,
    headers: {
      Authorization: "Bearer " + token,
    },
    timeout: timeout,
    timeoutErrorMessage: timeoutErrorMessage,
  };
  return await hitAPI(param);
};

export const admin = async (token, value, method, id) => {
  var url = null;
  switch (method) {
    case "put":
      url = process.env.REACT_APP_API_ADMIN + "update/" + id;
      break;
    case "delete":
      url = process.env.REACT_APP_API_ADMIN + "delete/" + value._id;
      break;
    default:
      //get or post
      url = process.env.REACT_APP_API_ADMIN;
      break;
  }
  let param = {};
  param.method = method;
  param.baseURL = process.env.REACT_APP_API_URL;
  param.url = url;
  param.headers = {
    "Content-Type": "form-data",
    Authorization: "Bearer " + token,
  };
  param.timeout = timeout;
  param.timeoutErrorMessage = timeoutErrorMessage;
  if (useData.includes(method)) param.data = value;

  return await hitAPI(param);
};

export const changePassword = async (token, value) => {
  let param = {
    method: "put",
    url: process.env.REACT_APP_API_CHANGE_PASS,
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    timeout: timeout,
    timeoutErrorMessage: timeoutErrorMessage,
    data: {
      old_password: value.oldpassword,
      new_password: value.newpassword,
    },
  };

  return await hitAPI(param);
};

export const branch = async (token, value, method, id) => {
  var url = null;
  let data = {};
  switch (method) {
    case "put":
      url = process.env.REACT_APP_API_BRANCH + "update/" + value._id;
      break;
    case "delete":
      url = process.env.REACT_APP_API_BRANCH + "delete/" + value._id;
      break;
    default:
      //get or post
      url = process.env.REACT_APP_API_BRANCH;
      break;
  }

  if (method === "put" || method === "post") {
    data = {
      name: value.name,
    };
  }
  console.log(data)
  let param = {};
  param.method = method;
  param.baseURL = process.env.REACT_APP_API_URL;
  param.url = url;
  param.headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  param.timeout = timeout;
  param.timeoutErrorMessage = timeoutErrorMessage;
  if (useData.includes(method)) param.data = data;

  return await hitAPI(param);
};

export const getTime = () => {
  let currDT = new Date();
  let hour =
    currDT.getHours() < 10 ? "0" + currDT.getHours() : currDT.getHours();
  let minute =
    currDT.getMinutes() < 10 ? "0" + currDT.getMinutes() : currDT.getMinutes();
  return hour + ":" + minute;
};
