import { Button, Form, Image, Col, InputGroup, Spinner } from "react-bootstrap";
import React, { Component } from "react";
import "../styles/Colour_Style.css";
import "../styles/Form_Style.css";
import { useForm, ErrorMessage } from "react-hook-form";
import { condensingWhitespace, handleNumberInput, handleTextInput } from "../logic/FormInput_Logic";
import { useState } from "react";
import { branch } from "../logic/handler";

function FormBranchMasterAsetFunCom(props) {
  const {
    register,
    errors,
    handleSubmit,
    clearError,
    setError,
    setValue,
    getValues,
  } = useForm({
    mode: "all",
    validateCriteriaMode: "all",
    defaultValues: {
      name: props.location.state ? props.location.state.name : ""
    },
  });

  const [state, setState] = useState({
    edit: props.location.state ? true : false,
    loading: false
  });

  const handleChange = (event) => {
    let val = "";
    val = handleTextInput(event.target.name, event);
    setValue(event.target.name, val);
  };

  const submit = async (value) => {
    setState({
      ...state,
      loading: true,
    });
    value._id = props.location.state ? props.location.state._id : null;
    value = condensingWhitespace(value)
    let success = false;
    let resp = await branch(
      localStorage.getItem("token"),
      value,
      state.edit ? "put" : "post"
    );
    if (resp[0]) {
      if (resp[0].status === 200) success = true;
      else {
      }
    } else {
      if (Array.isArray(resp[1].data)) {
        for (const key in resp[1].data) {
          props.generateToast(
            1,
            "Status " + resp[1].status + ": " + resp[1].data[key].msg
          );
        }
      } else
        props.generateToast(
          1,
          "Status " + resp[1].status + ": " + resp[1].message
        );
    }
    setState({
      ...state,
      loading: false,
    });
    console.log(resp[0].data)
    // if (success) props.showModal(state.edit ? "edit" : "input", resp[0].data);
    if (success) {
      state.edit
        ? props.showModal("edit", resp[0].data.name, resp[0].message)
        : props.showModal(
            "input",
            resp[0].data.name
          );
    }
  };

  return (
    <div className="container">
      {state.edit && (
        <h4>
          Sedang mengedit cabang: {props.location.state.name}
        </h4>
      )}
      <Form className="form" onSubmit={handleSubmit(submit)}>
        <Form.Row>
          {/* <Form.Group as={Col}>
            <Form.Label>No :</Form.Label>
            <Form.Control name="name" type="text" readOnly defaultValue={"1"} />
          </Form.Group> */}
          <Form.Group as={Col}>
            <Form.Label for="phone">Nama Cabang :</Form.Label>
            <Form.Control
              type="text"
              name="name"
              ref={register({ required: "Input tidak boleh kosong" })}
              
              onChange={handleChange}
            />
            <ErrorMessage errors={errors} name="name">
              {({ message }) => <p className="error_info">{message}</p>}
            </ErrorMessage>
          </Form.Group>
        </Form.Row>
        <div className="btn-container">
          <Button variant="primary" type="submit" disabled={state.loading}>
            {state.loading ? <Spinner animation="border" variant="secondary"/> : "Save"}
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default FormBranchMasterAsetFunCom;