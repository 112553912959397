import React, { Component } from "react";
import DetailDataCom from "../components/DetailDataMasterAset_Com";
import SideBar from "../components/SideBar_Com";

class DetailDataMasterAset extends Component {
    constructor(props){
        super(props);
    }

    render() {
        return (
            <div className="home">
                <SideBar/>
                <div className="main">
                    <DetailDataCom {...this.props}/>
                </div>
            </div>
        );
    }
}

export default DetailDataMasterAset;