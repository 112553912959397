import React, { Component } from "react";
import { Button, Image, Modal, Spinner, Form, Row, Col, InputGroup } from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import { Link } from "react-router-dom";
import SideBar from "../components/SideBar_Com";
import Table from "../components/Table_Com";
import { admin, aset } from "../logic/handler";
import "../styles/Table_Style.css";
import ServerError from "./ServerError_Page";
import Timeout from "./Timeout_Page";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

class TableAdminMasterAset extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      data: null,
      modal: false,
      type: null,
      gmcode: "prima3232",
      codeField: null,
      delLoading: false,
      delSuccess: null,
      error: null,
    };
    this.month = {
      0: "Januari",
      1: "Februari",
      2: "Maret",
      3: "April",
      4: "Mei",
      5: "Juni",
      6: "Juli",
      7: "Agustus",
      8: "September",
      9: "Oktober",
      10: "November",
      11: "Desember",
    };
    this.$ = props.dt;
  }

  componentDidMount() {
    this.$el = this.$(this.tableRef.current);
    var componentInstance = this;
    let dataTable = this.$el.DataTable({
      serverSide: false,
      scrollY: 341,
      scrollCollapse: true,
      processing: true,
      ajax: async (data, callback, settings) => {
        let resp = await admin(localStorage.getItem("token"), null, "get");
        console.log(resp);
        let dataInject = [];
        if (resp[0]) {
          if (resp[0].status === 200) {
            dataInject = resp[0].data;
            let i = 1;
            for (var obj in dataInject) {
              obj = {
                ...dataInject[obj],
                no: i,
              };
              dataInject[i - 1] = obj;
              i++;
            }
          }
          callback({
            data: dataInject,
          });
        } else {
          this.setState({
            error: resp[1].status,
          });
          return;
        }
      },
      columns: [
        {
          data: "name",
        },
        {
          data: "hp",
        },
        {
          data: "branch_id.name",
        },
        {
          data: "nik",
        },
        {
          data: "ktp",
          render: (data, type, meta, row) => {
            return '<div class="icon_container"><img class="icon ktp" src="assets/user.svg"/></div>';
          },
        },
        {
          data: "empty",
          render: (data, type, row, meta) => {
            return (
              '<div class="icon_container"><img class="icon detail edit" key="' +
              data +
              '" src="assets/edit.svg"/>' +
              '<img class="icon delete" key="' +
              data +
              '" src="assets/trash.svg"/>' +
              "</div>"
            );
          },
          orderable: false,
        },
      ],
      drawCallback: function () {
        const promise = new Promise((resolve, reject) => {
          //handle if user switch page too fast
          try {
            var api = this.api();
            var rowCount = api.rows({ page: "current" }).count();
            let td = "<td>&nbsp;</td>";
            for (let i = 0; i < api.columns()[0].length - 1; i++) {
              td = td + "<td></td>";
            }
            let rowType = rowCount % 2 === 1 ? "even" : "odd";
            for (
              var i = 0;
              i < api.page.len() - (rowCount === 0 ? 1 : rowCount);
              i++
            ) {
              componentInstance
                .$("#data_table tbody")
                .append(
                  componentInstance.$(
                    "<tr class='" + rowType + "' role='row'>" + td + "</tr>"
                  )
                );
              rowType = rowType === "odd" ? "even" : "odd";
            }
            resolve(true);
          } catch (err) {
            reject(err);
          }
        });
        promise.then(
          (resolve) => {
            return;
          },
          (reject) => {
            return;
          }
        );
      },
    });
    componentInstance.$("#data_table_info").appendTo(".info_paging_container");
    componentInstance
      .$("#data_table_paginate")
      .appendTo(".info_paging_container");
    dataTable.draw();
    dataTable.on("click", "img.detail", function () {
      let rowData = dataTable
        .row(componentInstance.$(this).parents("tr"))
        .data();
      componentInstance.props.history.push({
        pathname: "/FormAdminMasterAset",
        state: rowData,
      });
    });
    dataTable.on("click", "img.delete", function () {
      let rowData = dataTable
        .row(componentInstance.$(this).parents("tr"))
        .data();
      componentInstance.preview(rowData, "delete");
    });
    dataTable.on("click", "img.ktp", function () {
      let rowData = dataTable
        .row(componentInstance.$(this).parents("tr"))
        .data();
      componentInstance.preview(rowData.ktp, "preview");
    });
  }

  preview = (data, type) =>
    this.setState({ modal: true, data: data, type: type });

  prevModal = () =>
    this.setState({ modal: !this.state.modal }, () =>
      this.setState({ delSuccess: null })
    );

  delete = async () => {
    this.setState({
      delLoading: true,
    });
    let success = false;
    let resp = await admin(
      localStorage.getItem("token"),
      this.state.data,
      "delete"
    );
    console.log(resp);
    if (resp[0]) {
      if (resp[0].status === 200) {
        success = true;
      } else {
      }
    } else {
    }
    this.setState({
      delLoading: false,
      delSuccess: success,
    });
  };

  render() {
    return (
      <div className="home">
        <Modal
          backdrop={this.state.delLoading ? "static" : true}
          show={this.state.modal}
          onHide={this.prevModal}
        >
          <Modal.Header closeButton={!this.state.delLoading}>
            {this.state.type === "preview" && "Foto KTP"}
            {this.state.type === "delete" && "Konfirmasi"}
          </Modal.Header>
          <Modal.Body>
            {this.state.type === "preview" && (
              <Image src={this.state.data} thumbnail />
            )}
            {this.state.type === "delete" &&
              (this.state.delSuccess === null ? (
                <div>
                  <p>
                    Apakah anda yakin untuk menghapus data admin berikut ini:
                  </p>
                  <ul>
                    <li>Nama : {this.state.data.name}</li>
                    <li>No. HP: {this.state.data.hp}</li>
                    <li>E-mail: {this.state.data.email}</li>
                    <li>NIK : {this.state.data.nik}</li>
                    <li>
                      KTP : <Image src={this.state.data.ktp} thumbnail />
                    </li>
                  </ul>
                  <br />
                  <p>Untuk menghapus silahkan masukan Kode GrandMaster:</p>
                  <Form>
                  <Row className="justify-content-md-center">
                      <Col md="6">
                      <Form.Group>
                          <InputGroup>
                          <Form.Control
                              className="input-Password"
                              type={this.state.hidden ? "text" : "password"}
                              onChange={code =>
                              this.setState({
                                  codeField: code.target.value
                              })
                              }
                              disabled={this.state.loading}
                          />
                          <InputGroup.Append>
                              <InputGroup.Text>
                              {!this.state.hidden ? (
                                  <FontAwesomeIcon
                                  onClick={() => {
                                      this.toggleShowPassword();
                                  }}
                                  icon={faEyeSlash}
                                  />
                              ) : (
                                  <FontAwesomeIcon
                                  onClick={() => {
                                      this.toggleShowPassword();
                                  }}
                                  icon={faEye}
                                  />
                              )}
                              </InputGroup.Text>
                          </InputGroup.Append>
                          </InputGroup>
                          {/* <Form.Control 
                          type="text"
                          placeholder="Kode GrandMaster"
                          onChange={(code) => this.setState({
                              codeField: code.target.value
                          })}
                          /> */}
                      </Form.Group>
                      </Col>
                  </Row>
                  </Form>
                </div>
              ) : this.state.delSuccess ? (
                <p>
                  Data admin <b>{this.state.data.name}</b> telah berhasil
                  dihapus
                </p>
              ) : (
                <>
                  <p>
                    Perhatian: terjadi kesalahan ketika sedang mencoba menghapus
                    data admin.
                  </p>
                  <p>
                    Silahkan coba beberapa saat lagi, atau hubungi admin bila
                    masalah masih berlangsung.
                  </p>
                </>
              ))}
          </Modal.Body>
          <Modal.Footer>
            {this.state.type === "preview" && (
              <Button onClick={this.prevModal}>Tutup</Button>
            )}
            {this.state.type === "delete" && (
              <>
                {this.state.delSuccess === null && (
                  <Button
                    onClick={this.prevModal}
                    disabled={this.state.delLoading}
                  >
                    Batalkan
                  </Button>
                )}
                <Button
                  onClick={
                    this.state.delSuccess === null
                      ? this.delete
                      : this.state.delSuccess
                      ? () => window.location.reload()
                      : this.prevModal
                  }
                  variant="secondary"
                  style={{
                      cursor: this.state.codeField !== this.state.gmcode ?
                          "default" : "pointer"
                  }}
                  disabled={this.state.delLoading? true : this.state.codeField !== this.state.gmcode ?
                    true : false
                  }
                >
                  {this.state.delSuccess === null ? (
                    this.state.delLoading ? (
                      <Spinner variant="secondary" animation="border" />
                    ) : (
                      "Ya"
                    )
                  ) : (
                    "Tutup"
                  )}
                </Button>
              </>
            )}
          </Modal.Footer>
        </Modal>
        <SideBar />
        <div className="main">
          {!this.state.error ? (
            <>
              <div className="container-table">
                <Table type="Admin" childRef={this.tableRef} />
                <div id="contPagi" className="container-pagination">
                  <div className="dataTables_wrapper table_footer">
                    <div className="info_paging_container"></div>
                  </div>
                </div>
                <div id="btn-container">
                  <Link to="/FormAdminMasterAset">
                    <Button>Tambah</Button>
                  </Link>
                </div>
              </div>
            </>
          ) : this.state.error === 408 ? (
            <Timeout />
          ) : (
            <ServerError />
          )}
        </div>
      </div>
    );
  }
}

export default TableAdminMasterAset;
