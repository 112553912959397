import React, { Component } from "react";
import { Form, Button, Col, Modal, Spinner } from "react-bootstrap";
import "../styles/Colour_Style.css";
import "../styles/Form_Style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useForm, ErrorMessage } from "react-hook-form";
import { changePassword } from "../logic/handler";

function GantiPasswordCom(props) {
  const {
    register,
    handleSubmit,
    errors,
    reset,
    clearError,
    setError,
    getValues,
  } = useForm({
    mode: "all",
    validateCriteriaMode: "all",
  });

  const [state, setState] = useState({
    hiddenOld: false,
    hiddenNew: false,
    hiddenConfirm: false,
    loading: false,
    isSuccess: null,
  });

  const toggleShowOldPassword = () => {
    setState({
      ...state,
      hiddenOld: !state.hiddenOld,
    });
  };
  const toggleShowNewPassword = () => {
    setState({
      ...state,
      hiddenNew: !state.hiddenNew,
    });
  };
  const toggleShowConfirmPassword = () => {
    setState({
      ...state,
      hiddenConfirm: !state.hiddenConfirm,
    });
  };

  const matchPass = (value) => {
    clearError("missmatch");
    if (getValues("newpassword") === value) {
      clearError("confirm_pass");
      return true;
    } else {
      setError(
        "missmatch",
        "notMatch",
        "Password konfirmasi tidak sesuai dengan password baru"
      );
      return false;
    }
  };

  const submit = async (value) => {
    setState({
      ...state,
      loading: true,
    });
    let success = false;
    let resp = await changePassword(localStorage.getItem("token"), value);
    if (resp[0]) {
      if (resp[0].status === 200) {
        success = true;
      }
    } else {
      props.generateToast(1,"Status "+resp[1].status+": "+resp[1].message);
    }
    setState({
      ...state,
      loading: false,
      isSuccess: success,
    });
  };

  const close = () => {
    setState({...state, isSuccess: null});
    reset()
  };

  return (
    <>
      <div className="main-gantiPassword">
        <div className="main-gantiPassword-child">
          <div className="logo-container gantiPassword">
            <h1 style={{ fontSize: "1.5rem", textAlign: "center", padding: 20 }}>
              Anda Sedang Mengganti Password
            </h1>
          </div>
          <Form
            className="form-LoginForm gantiPassword"
            onSubmit={handleSubmit(submit)}
          >
            <Form.Group
              as={Col}
              className="form-group-GantiPassword"
              controlId="formBasicEmail"
            >
              <Form.Label>Password Lama :</Form.Label>
              <Form.Control
                className={errors.oldpassword && "invalid"}
                name="oldpassword"
                type={!state.hiddenOld ? "password" : "text"}
                ref={register({ required: "Input tidak boleh kosong" })}
                disabled={state.loading}
              />
              <div className="eye-logo">
                {!state.hiddenOld ? (
                  <FontAwesomeIcon
                    onClick={toggleShowOldPassword}
                    icon={faEye}
                  />
                ) : (
                  <FontAwesomeIcon
                    onClick={toggleShowOldPassword}
                    icon={faEyeSlash}
                  />
                )}
              </div>
            </Form.Group>
            <ErrorMessage errors={errors} name="oldpassword">
              {({ message }) => <p className="error_info">{message}</p>}
            </ErrorMessage>
            <Form.Group
              as={Col}
              className="form-group-GantiPassword"
              controlId="formBasicPassword"
            >
              <Form.Label>Password Baru :</Form.Label>
              <Form.Control
                className={errors.newpassword && "invalid"}
                name="newpassword"
                type={!state.hiddenNew ? "password" : "text"}
                minLength="8"
                ref={register({
                  required: "Input tidak boleh kosong",
                  minLength: {
                    value: 8,
                    message: "Password minimal 8 digit",
                  },
                  validate: {
                    same: (value) =>
                      value !== getValues("oldpassword") ||
                      "Password baru tidak boleh sama dengan password lama",
                  },
                })}
                disabled={state.loading}
              />
              <div className="eye-logo">
                {!state.hiddenNew ? (
                  <FontAwesomeIcon
                    onClick={toggleShowNewPassword}
                    icon={faEye}
                  />
                ) : (
                  <FontAwesomeIcon
                    onClick={toggleShowNewPassword}
                    icon={faEyeSlash}
                  />
                )}
              </div>
            </Form.Group>
            <ErrorMessage errors={errors} name="newpassword">
              {({ message }) => <p className="error_info">{message}</p>}
            </ErrorMessage>
            <Form.Group
              as={Col}
              className="form-group-GantiPassword"
              controlId="formBasicPassword"
            >
              <Form.Label>Konfirmasi Password :</Form.Label>
              <Form.Control
                className={errors.confirmpassword && "invalid"}
                name="confirmpassword"
                type={!state.hiddenConfirm ? "password" : "text"}
                ref={register({
                    required: "Input tidak boleh kosong",
                  validate: {
                    notMatch: (value) => matchPass(value),
                  },
                })}
                disabled={state.loading}
              />
              <div className="eye-logo">
                {!state.hiddenConfirm ? (
                  <FontAwesomeIcon
                    onClick={toggleShowConfirmPassword}
                    icon={faEye}
                  />
                ) : (
                  <FontAwesomeIcon
                    onClick={toggleShowConfirmPassword}
                    icon={faEyeSlash}
                  />
                )}
              </div>
            </Form.Group>
            <ErrorMessage errors={errors} name="confirmpassword">
              {({ message }) => <p className="error_info">{message}</p>}
            </ErrorMessage>
            <ErrorMessage errors={errors} name="missmatch">
              {({ message }) => <p className="error_info">{message}</p>}
            </ErrorMessage>
            <div className="btn-container gantiPassword">
              <Button variant="secondary" className="btn-secondary" onClick={reset}>
              Reset
            </Button>
              <Button
                variant="primary"
                id="btn-gantiPassword"
                type="submit"
                disabled={state.loading}
              >
                {state.loading ? (
                  <Spinner animation="border" variant="secondary" />
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          </Form>
        </div>
      </div>
      <Modal show={state.isSuccess} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Perhatian </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Password anda telah berhasil diganti.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default GantiPasswordCom;
