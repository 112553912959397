import React, { useEffect, useState } from "react";
import { useRef } from "react";



const generateHeader = (table) => {
  let res = [];
  switch (table) {
    case "Aset":
      res = [
        "No",
        "Jenis Barang",
        "Deskripsi",
        "Tanggal Terbit",
        "Tanggal Expired",
        "Status Expired",
        "",
        "",
      ];
      break;
    case "Admin":
      res = ["Nama Lengkap", "No. HP", "Cabang", "No. NIK","Atc. KTP",""];
      break;
    case "Branch":
      res = ["Nama Cabang",""];
      break;
    case "Log":
      res = ["Hari", "Tanggal", "Waktu", "Keterangan"];
      break;
    default:
      break;
  }
  return res;
};

function Table(props) {
  const head = generateHeader(props.type);
  console.log(head)

  return (
    <>
      <table id="data_table" className="stripe" width="100%" ref={props.childRef}>
        <thead>
            <tr>
                {head.map((idx)=>{
                    return <th>{idx}</th>
                })}
            </tr>
        </thead>
      </table>
    </>
  );
}

export default Table;
