import React from "react";
import { Route, Redirect } from "react-router-dom";
import Unauthorized from "./pages/Unauthorized_Page";

function PrivateRoute({ component: Component, dt: DT, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!localStorage.getItem("token")) return <Redirect to={{pathanme:"/",state:{code:-1}}}/>
        if (localStorage.getItem("isAdminMaster")==='true') {
          if (rest.level.includes(2)) return <Component dt={DT} {...props} />;
          else return <Unauthorized />;
        } else {
          if (rest.level.includes(1)) return <Component dt={DT} {...props} />;
          else return <Unauthorized />;
        }
      }}
    />
  );
}

export default PrivateRoute;
