import React, { Component } from "react";
import SideBar from "../components/SideBar_Com";
import Table from "../components/Table_Com";
import { log } from "../logic/handler";
import "../styles/Table_Style.css";
import ServerError from "./ServerError_Page";
import Timeout from "./Timeout_Page";

class TableLog extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      error: null,
    };
    this.month = {
      0: "Januari",
      1: "Februari",
      2: "Maret",
      3: "April",
      4: "Mei",
      5: "Juni",
      6: "Juli",
      7: "Agustus",
      8: "September",
      9: "Oktober",
      10: "Novebmer",
      11: "Desember",
    };
    this.$ = props.dt;
  }

  componentDidMount() {
    this.$el = this.$(this.tableRef.current);
    var componentInstance = this;
    let dataTable = this.$el.DataTable({
      serverSide: false,
      scrollY: 341,
      scrollCollapse: true,
      processing: true,
      ajax: async (data, callback, settings) => {
        let resp = await log(localStorage.getItem("token"));
        let dataInject = [];
        if (resp[0]) {
          if (resp[0].status === 200) {
            console.log(resp[0].data);
            dataInject = resp[0].data;
            let i = 1;
            for (var obj in dataInject) {
              obj = {
                ...dataInject[obj],
                no: i,
              };
              dataInject[i - 1] = obj;
              i++;
            }
            callback({
              data: dataInject,
            });
          }
        } else {
          this.setState({
            error: resp[1].status,
          });
          return;
        }
      },
      columns: [
        {
          data: "day",
        },
        {
          data: "created_at",
          render: (data, type, row, meta) => {
            let date = new Date(data);
            return (
              date.getDate() +
              " " +
              this.month[date.getMonth()] +
              " " +
              date.getFullYear()
            );
          },
        },
        {
          data: "created_at",
          render: (data, type, row, meta) => {
            let date = new Date(data);
            let hour =
              date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
            let minutes =
              date.getMinutes < 10
                ? "0" + date.getMinutes()
                : date.getMinutes();
            return hour + ":" + minutes;
          },
        },
        {
          data: "information",
        },
      ],
      order: [
        [1, "desc"],
        [2, "desc"],
      ],
      drawCallback: function () {
        const promise = new Promise((resolve, reject) => {
          //handle if user switch page too fast
          try {
            var api = this.api();
            var rowCount = api.rows({ page: "current" }).count();
            let td = "<td>&nbsp;</td>";
            for (let i = 0; i < api.columns()[0].length - 1; i++) {
              td = td + "<td></td>";
            }
            let rowType = rowCount % 2 === 1 ? "even" : "odd";
            for (
              var i = 0;
              i < api.page.len() - (rowCount === 0 ? 1 : rowCount);
              i++
            ) {
              componentInstance
                .$("#data_table tbody")
                .append(
                  componentInstance.$(
                    "<tr class='" + rowType + "' role='row'>" + td + "</tr>"
                  )
                );
              rowType = rowType === "odd" ? "even" : "odd";
            }
            resolve(true);
          } catch (err) {
            reject(err);
          }
        });
        promise.then(
          (resolve) => {
            return;
          },
          (reject) => {
            return;
          }
        );
      },
    });
    componentInstance.$("#data_table_info").appendTo(".info_paging_container");
    componentInstance
      .$("#data_table_paginate")
      .appendTo(".info_paging_container");
    dataTable.draw();
  }

  render() {
    return (
      <div className="home">
        <SideBar />
        <div className="main">
          {!this.state.error ? ( //entah kenapa, kalau conditional-rendering nya ngebungkus komponen Table akan error
            <div className="container-table"> 
              <Table type="Log" childRef={this.tableRef} />
              <div id="contPagi" className="container-pagination">
                  <div className="dataTables_wrapper table_footer">
                    <div className="info_paging_container"></div>
                  </div>
                </div>
            </div>
          ) : this.state.error === 408 ? ( //jangan dibungkus pake div
              <Timeout />
          ) : (
              <ServerError />
          )}
        </div>
      </div>
    );
  }
}

export default TableLog;
